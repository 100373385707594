"use client";
import "./about.scss";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import PageTitle from "../../Components/PageTitle";
import { FaUserFriends } from "react-icons/fa";
import { FaAward } from "react-icons/fa";
import { SlMagicWand } from "react-icons/sl";
import { PiArrowLeftThin } from "react-icons/pi";
import { PiArrowRightThin } from "react-icons/pi";
import { FaQuoteLeft } from "react-icons/fa6";
import { FaQuoteRight } from "react-icons/fa6";
import "animate.css";
import Lottie from "lottie-react";
import TransformingOldHouse from "../Json/TransformingOldHousesintoTimelessMasterpieces.json";
import FaqDiamondImage from "../Json/FaqDiamondImage.json";
function About() {
  return (
    <>
      <Header />
      <main className="aboutPageWrapper bg-light">
        {/* ========HEADER START======= */}
        <section className="headerWrapper bg-light ">
          <div id="carouselExampleDark" className="carousel  slide">
            <div className="carousel-inner">
              <div className="  carousel-item header1-Image active">
                <PageTitle
                  title="About us"
                  description="Explore Our Vision, Craftsmanship, and Dedication to Creating image1s That Transcend Trendsu to reach out to our dedicated team for any inquiries"
                />
              </div>
              <div className=" carousel-item  header2-Image">
                <PageTitle
                  title="About us"
                  description="Explore Our Vision, Craftsmanship, and Dedication to Creating image1s That Transcend Trendsu to reach out to our dedicated team for any inquiries"
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="prev"
            >
              <span className=" carousel-control-prev-icon" aria-hidden="true">
                <PiArrowLeftThin />
              </span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="next"
            >
              <span className=" carousel-control-next-icon" aria-hidden="true">
                <PiArrowRightThin />
              </span>
            </button>
          </div>
        </section>
        {/* ========HEADER END======= */}

        {/* ========ARTISTRY START======= */}
        <section className="artistryWrapper pt150 pb150 bg-light">
          <div className="container">
            <div className="row justify-content-center mb30">
              <div className="col-sm-9 mb30">
                <div className="primary-heading">
                  Unveiling the Artistry Behind Every
                </div>
                <p>
                  From Smart Living Innovations to Timeless Aesthetics, Discover
                  How We Elevate Home Beyond Expectations.
                </p>
              </div>
            </div>
            <div className="row justify-content-center mb30">
              <div className="col-sm-12">
                <div
                  id="carouselExampleCaptions"
                  className="carousel slide"
                  data-bs-ride="false"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="../../images/aboutTile1.png"
                        className="img-fluid"
                        alt="..."
                      />
                      <div className="carousel-caption ">
                        <div className="city-name">Los Angeles</div>
                        <div className="city-area">1234 Mangolia Avenue</div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img
                        src="../../images/aboutTile2.png"
                        className="img-fluid"
                        alt="..."
                      />
                      <div className="carousel-caption ">
                        <div className="city-name">London</div>
                        <div className="city-area">42 Baker Street</div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <img
                        src="../../images/aboutTile3.png"
                        className="img-fluid"
                        alt="..."
                      />
                      <div className="carousel-caption ">
                        <div className="city-name">Tokyo</div>
                        <div className="city-area">2-17-14 Shibuya ku</div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ========ARTISTRY END======= */}

        {/* ========CRAFTING START======= */}
        <section className="craftingWrapper  pb150 bg-light">
          <div className="container">
            <div className="row justify-content-center mb30">
              <div className="col-sm-10">
                <div className="primary-heading">
                  Crafting Dreams into Reality
                </div>
                <p>
                  Our journey began with a simple yet profound mission—to
                  transform living spaces into breathtaking works of art.
                </p>
              </div>
              <div className="crafting col-sm-12 ">
                <div className="craftingCardWrapper">
                  <div className="card">
                    <img
                      src="../../images/aboutTile4.png"
                      className="card-img-top img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="card">
                    <div className="card-body ">
                      <div className="card-icons">
                        <FaUserFriends />
                      </div>
                      <h5 className="card-title">Client-centric</h5>
                      <p className="card-des">
                        Our collaborative approach involves clients in
                        decision-making
                      </p>
                    </div>
                  </div>
                  <div className="card">
                    <img
                      src="../../images/aboutTile5.png"
                      className="card-img-top img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="card ">
                    <div className="card-body">
                      <div className="card-icons">
                        <FaAward />
                      </div>
                      <h5 className="card-title">Quality</h5>
                      <p className="card-des">
                        From selecting premium materials to executing intricate
                        details, our focus is on quality
                      </p>
                    </div>
                  </div>
                  <div className="card">
                    <img
                      src="../../images/aboutTile6.png"
                      className="card-img-top img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="card ">
                    <div className="card-body">
                      <div className="card-icons">
                        <SlMagicWand />
                      </div>
                      <h5 className="card-title">Renaissance</h5>
                      <p className="card-des">
                        Transforming old houses into modern and functional
                        living spaces.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ========CRAFTING END======= */}

        {/* ========TRANSFORMATION START======= */}
        <section className="transformationWrapper pb150 bg-light">
          <div className="container">
            <div className="row justify-content-center mb30">
              <div className="col-sm-9">
                <div className="primary-heading">
                  {" "}
                  Tailored Approach to Home Transformation
                </div>
                <p>
                  we believe in a holistic approach to remodeling – a journey
                  guided by collaboration, creativity, and precision.
                </p>
              </div>
              <ol className="timeline history-timeline">
                <li className="timeline-block">
                  <div className="timeline-icon">
                    <div className="timeline-counter">01</div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-sm-10">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-image">
                            <img
                              src="../../images/aboutTile7.png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <div className="cardDetailWrapper">
                            <div className="card-title">Consultation</div>
                            <div className="divider"></div>
                            <p>
                              Our experts engage in detailed discussions with
                              clients to understand their vision, and functional
                              requirements.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="timeline-block">
                  <div className="timeline-icon">
                    <div className="timeline-counter">02</div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-sm-10">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-image">
                            <img
                              src="../../images/aboutTile8.png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <div className="cardDetailWrapper">
                            <p className=" ">
                              House Coin's design team develops a customized
                              plan tailored to the client's unique needs.
                            </p>
                            <div className="divider"></div>
                            <div className="card-title   ">
                              Customised Planning
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="timeline-block">
                  <div className="timeline-icon">
                    <div className="timeline-counter">03</div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-sm-10">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-image">
                            <img
                              src="../../images/aboutTile9.png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <div className="cardDetailWrapper">
                            <div className="card-title">Quality Execution</div>
                            <div className="divider"></div>
                            <p>
                              Our skilled craftsmen, technicians, and project
                              managers work cohesively to bring the design to
                              life.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </section>
        {/* ========TRANSFORMATION END======= */}

        {/* ========TESTIMONIAL START======= */}
        <section className="testimonialWrapper pb150 bg-light">
          <div className="container">
            <div className="row justify-content-center mb30">
              <div className="col-sm-9">
                <div className="primary-heading"> Meet Our Design Maestros</div>
                <p>
                  With a passion for crafting timeless elegance, functional
                  beauty, and cutting-edge smart living solutions, our design
                  maestros are dedicated to redefining the way you experience
                </p>
              </div>
              <div className="col-md-10 col-lg-11 col-xl-9">
                <div className="card ">
                  <div className="d-flex flex-column justify-content-between align-items-center  flex-sm-column justify-content-sm-center align-items-sm-center flex-md-column  justify-content-md-center align-items-md-center flex-lg-row align-items-lg-start">
                    <div
                      className="nav  flex-md-row justify-content-md-between justify-content-lg-start  nav-pills "
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <button
                        className="nav-link active"
                        id="v-pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                      >
                        <img src="../../images/aboutTile10.png" alt="" />
                      </button>
                      <button
                        className="nav-link"
                        id="v-pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-profile"
                        aria-selected="false"
                      >
                        <img src="../../images/aboutTile11.png" alt="" />
                      </button>
                      <button
                        className="nav-link"
                        id="v-pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                      >
                        <img src="../../images/aboutTile12.png" alt="" />
                      </button>
                    </div>
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-home"
                        role="tabpanel"
                        aria-labelledby="v-pills-home-tab"
                      >
                        <div className="row ">
                          <div className="tab-image animate__animated animate__fadeInDown col-md-12 col-lg-6 ">
                            <img
                              src="../../images/aboutTile10.png"
                              className=" img-fluid"
                              alt=""
                            />
                          </div>
                          <div className=" tab-details animate__animated animate__fadeInUp col-md-12 col-lg-6">
                            <div className="heading">Christian cooper</div>
                            <div className="sub-heading">Design Director</div>
                            <p>
                              <span>
                                <FaQuoteLeft />
                              </span>
                              With over two decades of experience in the
                              industry, Elena Rodriguez leads our design team
                              with a passion for creating timeless yet
                              innovative spaces.
                              <span>
                                <FaQuoteRight />
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="v-pills-profile"
                        role="tabpanel"
                        aria-labelledby="v-pills-profile-tab"
                      >
                        <div className="row ">
                          <div className="tab-image animate__animated animate__fadeInDown col-md-12 col-lg-6">
                            <img
                              src="../../images/aboutTile11.png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <div className=" tab-details animate__animated animate__fadeInUp col-md-12 col-lg-6 ">
                            <div className="heading">Marcus Bennettr</div>
                            <div className="sub-heading">
                              Senior Interior Designer
                            </div>
                            <p>
                              <span>
                                <FaQuoteLeft />
                              </span>
                              As a seasoned Senior Interior Designer, Marcus
                              Bennett brings a wealth of knowledge in crafting
                              personalized design solutions. His portfolio is
                              gigantic.
                              <span>
                                <FaQuoteRight />
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="v-pills-messages"
                        role="tabpanel"
                        aria-labelledby="v-pills-messages-tab"
                      >
                        <div className="row ">
                          <div className="tab-image animate__animated animate__fadeInDown col-md-12 col-lg-6">
                            <img
                              src="../../images/aboutTile12.png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <div className="tab-details animate__animated animate__fadeInUp col-md-12 col-lg-6 ">
                            <div className="heading">Sophie Chang</div>
                            <div className="sub-heading">
                              Smart Living Specialist
                            </div>
                            <p>
                              <span>
                                <FaQuoteLeft />
                              </span>
                              Sophie Chang is at the forefront of the
                              intersection between technology and design. As our
                              Smart Living Specialist, she infuses cutting-edge
                              smart home solutions seamlessly
                              <span>
                                <FaQuoteRight />
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ========TESTIMONIAL END======= */}
        {/* ========TRANSFORMING SECTION START======= */}
        <Lottie animationData={TransformingOldHouse}/>
        {/* ========TRANSFORMING SECTION EEnd======= */}
        {/* ========DREAM HOME SECTION START======= */}

        <section className=" " id="about__dreamHome">
          <div className="container">
            <div className="row  justify-content-center justify-content-lg-between align-items-center">
              <div className="col-sm-10 col-md-10 col-lg-6 ">
                <div className="maskedImgWrapper">
                  <div className="verticle-bars top-bars">
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <div className="mask-imgs">
                    <img
                      src="../../images/dream-home.png"
                      alt=""
                      className="main-img img-fluid"
                    />
                  </div>

                  <div className="serviceImgText">
                    <p>Timeless Fusion Villa - Scenic Countryside</p>
                  </div>
                  <div className="small-img">
                    <img
                      src="../../images/dream-home-small.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="verticle-bars bottom-bars">
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xs-11 col-sm-11 col-md-8 col-lg-5">
                <div className="dreamHomeWrapper ">
                  <div className="tagsWrapper d-none d-lg-block">
                    <div className="tags">Elevate Your Living Experience</div>
                  </div>
                  <div className="primary-heading">
                    Your Dream Home, Our Expertise
                  </div>
                  <p className="">
                    Whether you seek a contemporary remodel, innovative smart
                    living solutions, or a seamless fusion of timeless design
                    and modern aesthetics, we're here to elevate your home
                    experience.
                  </p>

                  <div className="col-auto d-none d-lg-block">
                    <button className="page-btn btn-bordered mt-4 ">
                      <span>&#8593;</span> Contact now <span>&darr;</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-100">
              <div className="col-auto d-lg-none">
                <button className="page-btn btn-bordered mt-4 ">
                  <span>&#8593;</span> Contact now <span>&darr;</span>
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* ========DREAM HOME SECTION END======= */}
        {/* ========DESIGN SECTION START======= */}
        <section className="pb150" id="about__personaliseSolution">
          <div className="container">
            <div className="row  justify-content-center justify-content-lg-between ">
              <div className="col-xs-11 col-sm-11 col-md-8 col-lg-6">
                <div className="designWrapper ">
                  <div className="tagsWrapper d-none d-lg-block">
                    <div className="tags">Redefining Living Spaces</div>
                  </div>
                  <div className="primary-heading">
                    Personalized Solutions, Unparalleled Results
                  </div>
                  <p className="t">
                    Whether you aspire to revitalize a historic property, infuse
                    smart living seamlessly, or reimagine your interiors, our
                    team is dedicated to delivering unparalleled results.
                  </p>

                  <div className="col-auto d-none d-lg-block">
                    <button className="page-btn btn-bordered mt-4 ">
                      <span>&#8593;</span> Our blog <span>&darr;</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-10 col-md-10 col-lg-6 ">
                <div className="maskedImgWrapper">
                  <div className="verticle-bars top-bars">
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <div className="mask-imgs">
                    <img
                      src="../../images/about-ps-main1.png"
                      alt=""
                      className="main-img img-fluid"
                    />
                    <img
                      src="../../images/about-ps-main2.png"
                      alt=""
                      className="main-img img-fluid"
                    />
                  </div>

                  <div className="serviceImgText">
                    <p>Timeless Fusion Villa - Scenic Countryside</p>
                  </div>
                  <div className="small-img">
                    <img
                      src="../../images/about-ps-small.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="verticle-bars bottom-bars">
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-100">
              <div className="col-auto d-lg-none">
                <button className="page-btn btn-bordered mt-4 ">
                  <span> &#8593;</span> Our blog <span>&darr;</span>
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* ========DESIGN SECTION END======= */}
        {/* ========PROJECT START======= */}
        <section className="projectWrapper pb150 bg-light">
          <div className="container">
            <div className="row  justify-content-center">
              <div className="col-md-11">
                <div className="row justify-content-space-between">
                  <div className="col-sm-4">
                    <div className="projectItemWrap">
                      <img
                        src="../../images/aboutTile13.png"
                        alt=""
                        className="product-image  img-fluid "
                      />
                      <div className="overlay-bottom-slide">
                        <div className="product-description">
                          <div className="product-heading">
                            Pursuit of Excellence
                          </div>
                          <p>
                            Excellence begins with a commitment to quality. At
                            House Coin, we select materials with precision,
                            employ skilled artisans, and enforce stringent
                            quality control measures at every stage of a
                            project.
                          </p>
                          <button className="page-btn btn-bg">
                            <span> &#8593; </span>VIEW PRODUCT{" "}
                            <span> &darr; </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="projectItemWrap">
                      <img
                        src="../../images/aboutTile14.png"
                        alt=""
                        className="product-image img-fluid "
                      />
                      <div className="overlay-bottom-slide">
                        <div className="product-description">
                          <div className="product-heading">
                            Unwavering Integrity
                          </div>
                          <p>
                            We understand that the trust you place in us is
                            paramount, and we uphold the highest standards of
                            unwavering integrity in every aspect of our work.
                          </p>
                          <button className="page-btn btn-bg">
                            <span> &#8593; </span> VIEW PRODUCT{" "}
                            <span> &darr; </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="projectItemWrap">
                      <img
                        src="../../images/aboutTile15.png"
                        alt=""
                        className="product-image  img-fluid "
                      />
                      <div className="overlay-bottom-slide">
                        <div className="product-description">
                          <div className="product-heading">
                            Expertise and Insight
                          </div>
                          <p>
                            Our journey is fueled by a dynamic fusion of
                            expertise and insightful design, where every project
                            is an opportunity to showcase our deep understanding
                            of the craft.
                          </p>
                          <button className="page-btn btn-bg ">
                            <span> &#8593; </span>VIEW PRODUCT{" "}
                            <span> &darr; </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ========PROJECT END======= */}

        {/* ========FAQ START======= */}
        <section className="FAQWrapper bg-light">
          <div className="container-fluid">
            <div className="row">
              <div className="d-none  d-lg-block col-md-6 p-0">
                <Lottie animationData={FaqDiamondImage}/>
              </div>
              <div className="col-md-12 col-lg-6  p-0">
                <div className="FAQbox">
                  <button className="page-btn">Squash your curiosity</button>
                  <div className="FAQTitle">Get The Answers You Need</div>
                  <div className="accordion " id="myAccordion">
                    <div className="accordion-item ">
                      <div className="accordion-header" id="headingOne">
                        <button
                          type="button"
                          className="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          What services does House Coin offer?
                        </button>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse"
                          data-bs-parent="#myAccordion"
                        >
                          <div className="card-body">
                            <p>
                              House Coin offers a comprehensive suite of
                              services, including remodeling, renovation,
                              interior design, smart home integration, and
                              sustainable design solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-header" id="headingTwo">
                        <button
                          type="button"
                          className="accordion-button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                        >
                          How does the remodeling process work at House Coin?
                        </button>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapsed"
                          data-bs-parent="#myAccordion"
                        >
                          <div className="card-body">
                            <p>
                              The remodeling process at House Coin begins with a
                              consultation to understand your vision. We then
                              proceed to create a customized design
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-header" id="headingThree">
                        <button
                          type="button"
                          className="accordion-button collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                        >
                          How do you ensure the quality of your craftsmanship?
                        </button>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapsed"
                          data-bs-parent="#myAccordion"
                        >
                          <div className="card-body">
                            <p>
                              Quality is at the core of our values. House Coin
                              employs skilled artisans and uses premium
                              materials to ensure superior craftsmanship.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-auto">
                      <div className="getInTouch">
                        <button type="submit" className="page-btn btn-bg mt-4">
                          Get in touch
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ========FAQ END======= */}
      </main>
      {/* ========FOOTER START======= */}
      <Footer />
      {/* ========FOOTER END======= */}
    </>
  );
}

export default About;

"use client";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { FaStar } from "react-icons/fa6";
import "./home.scss";
import Lottie from "lottie-react";
import Portfolio from "../Json/portfolio.json";
import HomePageHero from "../Json/HomePageHero.json";
const Home = () => {
  return (
    <>
      <Header />
      <main className="homePageWrapper bg-light">
        <Lottie animationData={HomePageHero}/>
        {/* MOBILE CAROUSEL START */}
        <section className="pt150 pb150" id="mobileCarousel">
          <div className="container">
            <div className="row justify-content-center d-column">
              <div className="col-sm-12 col-md-9 col-lg-8">
                <div className="primary-heading">
                  Celebrating Timeless Elegance in Every Space
                </div>
                <p className="pt-2">
                  My commitment to staying updated with the latest industry
                  trends and tools ensures that we deliver cutting-edge user
                  experiences in every project.
                </p>
              </div>
              <div className="col-12">
                <div className="row justify-content-sm-center justify-content-lg-between ">
                  <div className=" d-none d-lg-block col-md-6 col-lg-2 ">
                    <div className="verImageWrapper">
                      <div className="imgCol">
                        <img
                          src="../../images/mb-side1.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="imgCol">
                        <img
                          src="../../images/mb-side2.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="imgCol">
                        <img
                          src="../../images/mb-side3.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" col-sm-8 col-lg-8 mobile-bgContainer  ">
                    <div className="side-bars left-bars">
                      <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                    <div className="mobilebg-wrapper">
                      <div className="mobileContainer">
                        <div className="imgCarousel">
                          <div
                            id="carouselExampleAutoplaying"
                            className="carousel slide"
                            data-bs-ride="carousel"
                          >
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <img
                                  src="../../images/mbCar-img1.jpeg"
                                  className="d-block w-100"
                                  alt="..."
                                />
                              </div>
                              <div className="carousel-item">
                                <img
                                  src="../../images/mobile1.jpeg"
                                  className="d-block w-100"
                                  alt="..."
                                />
                              </div>
                              <div className="carousel-item">
                                <img
                                  src="../../images/mobile2.jpeg"
                                  className="d-block w-100"
                                  alt="..."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sensorIcon">
                          <img
                            src="../../images/icons/sensor.svg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="side-bars right-bars">
                      <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="d-none d-lg-block col-lg-2 right-side ">
                    <div className="verImageWrapper">
                      <div className="imgCol">
                        <img
                          src="../../images/mb-side5.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="imgCol">
                        <img
                          src="../../images/mb-side4.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="imgCol">
                        <img
                          src="../../images/mb-side6.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* MOBILE CAROUSEL END */}
        {/* ========PORTFOLIO SECTION (ANIMATED SLIDES) START======= */}
        <Lottie animationData={Portfolio}/>
        {/* ========PORTFOLIO SECTION (ANIMATED SLIDES) END======= */}
        {/* ========SERVICES SECTION START======= */}
        <section className=" pt150 " id="ourServices">
          <div className="container">
            <div className="row  justify-content-center justify-content-lg-between ">
              <div className="col-sm-9 col-md-8 col-lg-5 ">
                <div className="serviceImgWrapper">
                  <div className="side-bars left-bars">
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <div className="small-img">
                    <img
                      src="../../images/service-sm-img.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <img
                    src="../../images/mask1.png"
                    alt=""
                    className="main-img img-fluid"
                  />
                  <div className="serviceImgText">
                    <p>The Manse - Epitome of Luxury Living</p>
                  </div>
                  <div className="verticle-bars bottom-bars">
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xs-11 col-sm-10 col-md-10 col-lg-6  order-first order-sm-first order-md-first order-lg-last">
                <div className="serviceWrpper">
                  <div className="primary-heading ">
                    Exploring Smart Living through our services
                  </div>
                  <p className="">
                    My commitment to staying updated with the latest industry
                    trends and tools ensures that I deliver cutting-edge user
                    experiences in every project.
                  </p>
                  <div className="tagsWrapper">
                    <div className="tags">Electrical</div>
                    <div className="tags">Plumbing</div>
                    <div className="tags">Pool</div>
                    <div className="tags">Flooring</div>
                    <div className="tags">Roofing</div>
                    <div className="tags">Windows</div>
                    <div className="tags">Roofing</div>
                    <div className="tags">Windows</div>
                  </div>
                </div>
                <div className="col-auto">
                  <button className="page-btn btn-bordered mt-4 ">
                    <span>&#8593;</span> Contact now <span>&darr;</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ========SERVICES SECTION END======= */}
        {/* ========DESIGN SECTION START======= */}
        <section className="pb150" id="ourDesigns">
          <div className="container">
            <div className="row  justify-content-center justify-content-lg-between ">
              <div className="col-xs-11 col-sm-11 col-md-11 col-lg-6 ">
                <div className="designWrapper ">
                  <div className="primary-heading">
                    Redefine the way we live, through our designs
                  </div>
                  <p className="t">
                    My commitment to staying updated with the latest industry
                    trends and tools ensures that I deliver cutting-edge user
                    experiences in every project.
                  </p>
                  <div className="tagsWrapper">
                    <div className="tags">Landscaping</div>
                    <div className="tags">Virtualisation</div>
                    <div className="tags">Cabinets</div>
                    <div className="tags">Offices</div>
                    <div className="tags">Living</div>
                    <div className="tags">Kitchen</div>
                    <div className="tags">Bathrooms</div>
                  </div>
                  <div className="col-auto">
                    <button className="page-btn btn-bordered mt-4 ">
                      <span>&#8593;</span> About us <span>&darr;</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-10 col-md-8 col-lg-6 ">
                <div className="maskedImgWrapper">
                  <div className="verticle-bars top-bars">
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <img
                    src="../../images/sec5-mask-img.png"
                    alt=""
                    className="main-img img-fluid"
                  />
                  <div className="serviceImgText">
                    <p>The Snuggery - Blissfully peaceful haven</p>
                  </div>
                  <div className="small-img">
                    <img
                      src="../../images/sec5-sm.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="verticle-bars bottom-bars">
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ========DESIGN SECTION END======= */}
        {/* DISCOVER QUALITY START */}
        <section className="pb150" id="discoverQuality">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-9">
                <div className="primary-heading">
                  Discover Quality Craftmanship
                </div>
                <p>
                  Join us as we explore the art of remodeling, renovation, and
                  interior design, unveiling the magic behind every successful
                  project.
                </p>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="qualityWrapper">
                  <div className="qualityItem">
                    <img src="../../images/icons/q1.svg" alt="" />
                    <p>Customer Service available 24/7</p>
                  </div>
                  <div className="qualityItem">
                    <img src="../../images/icons/q2.svg" alt="" />
                    <p>Elegant Interior design</p>
                  </div>
                  <div className="qualityItem">
                    <img src="../../images/icons/q3.svg" alt="" />
                    <p>Eco-friendly build</p>
                  </div>
                  <div className="qualityItem">
                    <img src="../../images/icons/q4.svg" alt="" />
                    <p>Smart home system</p>
                  </div>
                  <div className="qualityItem">
                    <img src="../../images/icons/q5.svg" alt="" />
                    <p>Privacy and seclusion</p>
                  </div>
                  <div className="qualityItem">
                    <img src="../../images/icons/q6.svg" alt="" />
                    <p>Client-Centric Approach</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* DISCOVER QUALITY END */}

        {/* ========PROJECTS SECTION (ANIMATED SLIDES) START======= */}
        <section className="" id="project-sliders">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-12">
                <div className="slider-container">
                  <div className="flexbox-slider flexbox-slider-1">
                    <div
                      className="flexbox-slide"
                      style={{
                        backgroundImage: `url("../../images/hero-home.png")`,
                      }}
                    >
                      <div className="content-block">
                        <h3>Modern Haven in the City</h3>
                        <p>
                          In the heart of the bustling city, House Coin had the
                          privilege of transforming a compact urban apartment
                          into a modern haven.
                        </p>
                        <div className="row justify-content-center">
                          <div className="col-auto">
                            <button className="page-btn btn-bordered mt-4">
                              <span>&#8593;</span> VIEW PROJECT
                              <span>&darr;</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flexbox-slide"
                      style={{
                        backgroundImage: `url("../../images/hero-about.png")`,
                      }}
                    >
                      <div className="content-block">
                        <h3>Modern Haven in the City</h3>
                        <p>
                          In the heart of the bustling city, House Coin had the
                          privilege of transforming a compact urban apartment
                          into a modern haven.
                        </p>
                        <div className="row justify-content-center">
                          <div className="col-auto">
                            <button className="page-btn btn-bordered mt-4">
                              <span>&#8593;</span> VIEW PROJECT
                              <span>&darr;</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flexbox-slide"
                      style={{
                        backgroundImage: `url("../../images/hero-blog.png")`,
                      }}
                    >
                      <div className="content-block">
                        <h3>Modern Haven in the City</h3>
                        <p>
                          In the heart of the bustling city, House Coin had the
                          privilege of transforming a compact urban apartment
                          into a modern haven.
                        </p>
                        <div className="row justify-content-center">
                          <div className="col-auto">
                            <button className="page-btn btn-bordered mt-4">
                              <span>&#8593;</span> VIEW PROJECT
                              <span>&darr;</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flexbox-slide"
                      style={{
                        backgroundImage: `url("../../images/hero-agent.png")`,
                      }}
                    >
                      <div className="content-block">
                        <h3>Modern Haven in the City</h3>
                        <p>
                          In the heart of the bustling city, House Coin had the
                          privilege of transforming a compact urban apartment
                          into a modern haven.
                        </p>
                        <div className="row justify-content-center">
                          <div className="col-auto">
                            <button className="page-btn btn-bordered mt-4">
                              <span>&#8593;</span> VIEW PROJECT
                              <span>&darr;</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="flexbox-slide"
                      style={{
                        backgroundImage: `url("../../images/hero-contact.png")`,
                      }}
                    >
                      <div className="content-block">
                        <h3>Modern Haven in the City</h3>
                        <p>
                          In the heart of the bustling city, House Coin had the
                          privilege of transforming a compact urban apartment
                          into a modern haven.
                        </p>
                        <div className="row justify-content-center">
                          <div className="col-auto">
                            <button className="page-btn btn-bordered mt-4">
                              <span>&#8593;</span> VIEW PROJECT
                              <span>&darr;</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ========PROJECTS SECTION (ANIMATED SLIDES) END======= */}
        {/* ========PROJECTS Categories START======= */}
        <section className="pt150 pb150" id="project-categories">
          <div className="container">
            <div className="row justify-content-center justify-content-lg-between align-items-center gap-md-100">
              <div className="col-md-10 col-lg-5">
                <div className="project-des-wrapper d-flex flex-column">
                  <h3>Our Esteemed and virtuous Projects</h3>
                  <div className="row d-none d-lg-block">
                    <div className="col-auto">
                      <div className=" tags">Timeless Beauty</div>
                    </div>
                  </div>
                  <p className="d-none d-lg-block">
                    Building moments as a permanance
                  </p>

                  <div className="row d-none d-lg-block">
                    <div className="col-auto">
                      <div className=" tags">Enduring Value</div>
                    </div>
                  </div>
                  <p className="d-none d-lg-block">
                    Smile while looking things you can’t remember
                  </p>
                  <div className="row d-none d-lg-block">
                    <div className="col-auto">
                      <div className=" tags">Innovative Lifestyle</div>
                    </div>
                  </div>
                  <p>
                    Our smart living solutions not only enhance convenience but
                    also elevate your lifestyle, providing a level of comfort
                    and security that's truly unmatched.
                  </p>
                  <div className="row d-none d-lg-block">
                    <div className="col-auto">
                      <button className="page-btn btn-bordered ">
                        <span>&#8593;</span> See all projects
                        <span>&darr;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="img-tile-wrapper">
                  <p>Cape Breeze Retreat - A charming Cape-style home</p>
                  <div className="img_tile">
                    <img
                      src="../../images/project-img1.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <small>
                    Designed for the serene lifestyle of empty nesters.
                  </small>
                </div>
              </div>
              <div className="col-md-8 col-lg-6 col-xl-5 mt-5">
                <div className="img-tile-wrapper">
                  <p>Mid-Century Marvel - A stylish mid-century modern </p>
                  <div className="img_tile">
                    <img
                      src="../../images/project-img2.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <small>Angelic Residence designed with timeless appeal</small>
                </div>
              </div>
              <div className="col-md-8 col-lg-6 col-xl-5 mt-5">
                <div className="bar-tile-wrapper">
                  <div className="verticle-bars top-bars">
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <div className="img-tile-wrapper">
                    <p>Rustic Haven in the Woods - A cozy, rustic retreat</p>
                    <div className="img_tile">
                      <img
                        src="../../images/project-img3.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <small>Tucked away in the heart of nature</small>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-lg-6 col-xl-5 mt-5">
                <div className="bar-tile-wrapper">
                  <div className="verticle-bars top-bars right-bar">
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <div className="img-tile-wrapper">
                    <p>
                      Seaside Serenity Villa - An elegant coastal escape with
                      views of the ocean
                    </p>
                    <div className="img_tile">
                      <img
                        src="../../images/project-img3.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <small>Perfect for relaxation and rejuvenation</small>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-lg-6 col-xl-5 mt-5">
                <div className="img-tile-wrapper">
                  <p>Mid-Century Marvel - A stylish mid-century modern </p>
                  <div className="img_tile">
                    <img
                      src="../../images/project-img2.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <small>Angelic Residence designed with timeless appeal</small>
                </div>
              </div>
              <div className="col-md-8 col-lg-6 col-xl-5 mt-5">
                <div className="img-tile-wrapper">
                  <p>Mid-Century Marvel - A stylish mid-century modern </p>
                  <div className="img_tile">
                    <img
                      src="../../images/project-img2.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <small>Angelic Residence designed with timeless appeal</small>
                </div>
              </div>
              <div className="col-md-8 col-lg-6 col-xl-5 mt-5">
                <div className="bar-tile-wrapper">
                  <div className="verticle-bars top-bars">
                    <ul>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <div className="img-tile-wrapper">
                    <p>
                      Seaside Serenity Villa - An elegant coastal escape with
                      views of the ocean
                    </p>
                    <div className="img_tile">
                      <img
                        src="../../images/project-img3.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <small>Perfect for relaxation and rejuvenation</small>
                  </div>
                </div>
              </div>
              <div className="col-auto d-block d-lg-none">
                <button className="page-btn btn-bordered ">
                  <span>&#8593;</span> See all projects
                  <span>&darr;</span>
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* ========PROJECTS Categories END======= */}
        {/* ========OUR CLIENTS Start======= */}
        <section className="pb150" id="ourClients">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-7">
                <div className="primary-heading">Our Clients</div>
                <p className="pt-4">
                  The success stories of our clients are a testament to our
                  unwavering commitment to delivering exceptional results.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="client-wrapperContainer d-flex align-items-center">
                  <div className=" clients-col d-flex flex-column order-1 order-sm-1 order-md-1 ">
                    <div className="client">
                      <img
                        src="../../images/client1.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="client">
                      <img
                        src="../../images/client2.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="client">
                      <img
                        src="../../images/client3.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="middle-imgWrapper d-flex flex-column justify-content-center order-3 order-sm-3 order-md-3 order-lg-2">
                    <div className="middle-img">
                      <img
                        src="../../images/client-img.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-auto">
                        <button className="page-btn btn-bordered ">
                          <span>&#8593;</span> Book today
                          <span>&darr;</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className=" clients-col client-right d-flex flex-column order-2 order-sm-2 order-md-2 order-lg-3">
                    <div className="client">
                      <img
                        src="../../images/client4.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="client">
                      <img
                        src="../../images/client5.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="client">
                      <img
                        src="../../images/client6.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ========OUR CLIENTS END======= */}
        {/* ========TESTIMONIAL START======= */}
        <section className="pb150" id="testimonials">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-9">
                <div className="primary-heading">Testimonials</div>
                <p className="pt-2">
                  At House Coin, the satisfaction and experiences of our clients
                  are at the heart of everything we do.
                </p>
              </div>
            </div>
            <div className="row justify-content-center mt-4">
              <div className=" col-md-6 col-lg-12">
                <div className="testimonial-wrapper">
                  <div className="testimonial-item d-flex align-items-center justify-content-center flex-column">
                    <div className="testimonial-content">
                      <p className="clientName">Sarah Johnson</p>
                      <p className="comment">
                        “I cannot speak highly enough about House Coin. From the
                        initial consultation to the final walk-through, the
                        entire team displayed professionalism”{" "}
                      </p>

                      <span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </span>
                    </div>
                    <div className="testimonial-img">
                      <img src="../../images/testimonial1.png" alt="" />
                    </div>
                  </div>
                  <div className="testimonial-item d-flex align-items-center justify-content-center flex-column">
                    <div className="testimonial-content">
                      <p className="clientName">David Williams</p>
                      <p className="comment">
                        "I was looking for a company that could restore the
                        classic charm of my old home. House Coin exceeded my
                        expectations immensly”{" "}
                      </p>

                      <span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </span>
                    </div>
                    <div className="testimonial-img">
                      <img src="../../images/testimonial2.png" alt="" />
                    </div>
                  </div>
                  <div className="testimonial-item d-flex align-items-center justify-content-center flex-column">
                    <div className="testimonial-content">
                      <p className="clientName">David Williams</p>
                      <p className="comment">
                        "I was looking for a company that could restore the
                        classic charm of my old home. House Coin exceeded my
                        expectations immensly”{" "}
                      </p>

                      <span>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </span>
                    </div>
                    <div className="testimonial-img">
                      <img src="../../images/testimonial3.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ========TESTIMONIAL END======= */}
        {/* ========Registration Start======= */}

        <section id="registration">
          <div className="container">
            <div className="registrationWrapper">
              <div className="row justify-content-center justify-content-lg-between">
                <div className="col-md-7 col-lg-4">
                  <div className="registerContent">
                    <button className="page-btn btn-bg">INTERESTED ?</button>
                    <div className="secTitle">Register Interest</div>
                    <p>
                      Submit your details through our form to register your
                      interest
                    </p>
                  </div>
                </div>
                <div className="col-md-7 col-lg-5">
                  <div className="registerForm">
                    <form>
                      <div className="row form-group">
                        <label>
                          Full Name
                          <input type="text" className="form-control col" />
                        </label>
                      </div>
                      <div className="row form-group">
                        <label>
                          Phone number
                          <input type="text" className="form-control" />
                        </label>
                      </div>
                      <div className="row form-group">
                        <label>
                          Email Address
                          <input type="text" className="form-control" />
                        </label>
                      </div>
                      <div className="row form-check">
                        <label className="form-check-label">
                          <input type="checkbox" className="form-check-input" />
                          If you do not want to receive future updates via
                          email, please check this box
                        </label>
                      </div>
                      <div className="row justify-content-center justify-content-lg-start">
                        <div className="col-auto">
                          <button type="submit" className="page-btn btn-bg">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ========Registration END======= */}

        {/* ABout section */}
      </main>
      <Footer />
    </>
  );
};

export default Home;

"use client";
import "./agents.scss";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { FaMeta } from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa";
import Lottie from "lottie-react";
import AgentPageHero from "../Json/AgentPageHero.json";
import AgentPageRegisterationDiomond from "../Json/AgentPageRegisterationDiomond.json";
function Agents() {
  return (
    <>
      <Header />
      <main className="agentsPageWrapper">
        <Lottie animationData={AgentPageHero} />
        <section className="sectionPadding pt150 pb150 bg-light" >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="primary-heading "> Meet Our Team</div>
                <p>When it comes to the world of luxury real estate, our agents are unmatched in their expertise and commitment to delivering unparalleled results..
                </p>
              </div>
              <div className="col-lg-10">
                <div className="row agentTeam">
                  <div className=" col-sm-6 col-lg-4">
                    <div className="card ">
                      <div className="card-body">
                        <div className="row">
                          <div className=" card-image col-sm-8">
                            <img src="../../images/agentTile1.png" alt="" />
                          </div>
                          <div className=" card-icon col-sm-1">
                            <div id="icon-1"><FaMeta /></div>
                            <div id="icon-2"><BsTwitterX /></div>
                            <div id="icon-3"><FaInstagram /></div>
                          </div>
                          <div className="card-content">
                            <div>Robert parker</div>
                            <div>Realtor</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-sm-6 col-lg-4">
                    <div className="card ">
                      <div className="card-body">
                        <div className="row">
                          <div className=" card-image col-sm-8">
                            <img src="../../images/agentTile2.png" alt="" />
                          </div>
                          <div className=" card-icon col-sm-1">
                            <div id="icon-1"><FaMeta /></div>
                            <div id="icon-2"><BsTwitterX /></div>
                            <div id="icon-3"><FaInstagram /></div>
                          </div>
                          <div className="card-content">
                            <div>Scarlett gray</div>
                            <div>Realtor</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-sm-6 col-lg-4">
                    <div className="card ">
                      <div className="card-body">
                        <div className="row">
                          <div className=" card-image col-sm-8">
                            <img src="../../images/agentTile3.png" alt="" />
                          </div>
                          <div className=" card-icon col-sm-1">
                            <div id="icon-1"><FaMeta /></div>
                            <div id="icon-2"><BsTwitterX /></div>
                            <div id="icon-3"><FaInstagram /></div>
                          </div>
                          <div className="card-content">
                            <div>Noah cooper</div>
                            <div>Realtor</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-sm-6 col-lg-4">
                    <div className="card ">
                      <div className="card-body">
                        <div className="row">
                          <div className=" card-image col-sm-8">
                            <img src="../../images/agentTile4.png" alt="" />
                          </div>
                          <div className=" card-icon col-sm-1">
                            <div id="icon-1"><FaMeta /></div>
                            <div id="icon-2"><BsTwitterX /></div>
                            <div id="icon-3"><FaInstagram /></div>
                          </div>
                          <div className="card-content">
                            <div>Elijah Turner</div>
                            <div>Structural Engineer</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-sm-6 col-lg-4">
                    <div className="card ">
                      <div className="card-body">
                        <div className="row">
                          <div className=" card-image col-sm-8">
                            <img src="../../images/agentTile5.png" alt="" />
                          </div>
                          <div className=" card-icon col-sm-1">
                            <div id="icon-1"><FaMeta /></div>
                            <div id="icon-2"><BsTwitterX /></div>
                            <div id="icon-3"><FaInstagram /></div>
                          </div>
                          <div className="card-content">
                            <div>Aria Sanchez</div>
                            <div>Project Coordinator</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" col-sm-6 col-lg-4">
                    <div className="card ">
                      <div className="card-body">
                        <div className="row">
                          <div className=" card-image col-sm-8">
                            <img src="../../images/agentTile6.png" alt="" />
                          </div>
                          <div className=" card-icon col-sm-1">
                            <div id="icon-1"><FaMeta /></div>
                            <div id="icon-2"><BsTwitterX /></div>
                            <div id="icon-3"><FaInstagram /></div>
                          </div>
                          <div className="card-content">
                            <div>Zoe Crawford</div>
                            <div>Logistics Coordinator</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
              <div className="col-auto">
                <button className="page-btn btn-bordered  ">
                  <span> &#8593;</span> Join today <span> &darr;</span>
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="sectionPadding1">
          <div className="container-fluid">
            <div className="row">
              <div className="d-none d-md-block col-md-6 p-0">
                <Lottie animationData={AgentPageRegisterationDiomond} />
              </div>
              <div className="col-md-6 p-0">
                <div className="formBx">
                  <button className="page-btn btn-bg mt-4">Contact us</button>
                  <div className="formTitle">
                    Your Satisfaction Is Paramount
                  </div>
                  <div className="formContent">
                    With an unwavering commitment to excellence, our team of experienced professionals is   ready to provide personalized assistance and guidance.
                  </div>
                  <div className="submit-button">
                    <button type="submit" className="page-btn btn-bg mt-4">Get in touch</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
export default Agents;

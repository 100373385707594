import React from "react";
import "./index.scss";
import { useRef } from "react";
import { Link } from "react-router-dom";
// import { useEffect } from "react";

function Header() {
  // const [headerColor, setHeaderColor] = React.useState("transparent");
  const headerRef = useRef<HTMLDivElement>(null);
  const listenScrollEvent = () => {
    if (window.scrollY > 10) {
      // setHeaderColor("black");
      headerRef.current?.classList.add("scrolled");
      return;
    }
    headerRef.current?.classList.remove("scrolled");
    // setHeaderColor("transparent");

    // window.scrollY > 10 ? setHeaderColor("black") : setHeaderColor("white");
  };

  // useEffect(() => {
  //   window.addEventListener("scroll", listenScrollEvent);
  // }, []);
  React.useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);
  // console.log("component reder");
  return (
    <>
      <nav
        className="navbar fixed-top navbar-expand-lg "
        id="menuWrapper"
        // style={{ backgroundColor: headerColor }}
        ref={headerRef}
      >
        <div className="container-fluid">
          <a className="navbar-brand " href="/">
            <img src="../../images/logo.svg" alt="logo" className="img-fluid" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active " aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#">
                  Design
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="/#">
                  Solution
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#">
                  Services
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="/#">
                  Portfolio
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/blog">
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/agents">
                  Agents
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
            <form className="d-flex ms-auto">
              <button className="btn  page-btn" type="submit">
                Search <img src="../../images/icons/search.svg" alt="" />
              </button>
            </form>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;

import React from "react";
import "./pageTitle.scss";
const PageTitle = (props: { title: any; description: any }) => {
  return (
    <>
      <section className="heroBannerWrapper">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className="titleWrapper ">
                <div className="pageTitle">{props.title}</div>
                <div className="titleDescription">{props.description}</div>
                <button className="page-btn btn-bg mt-4">
                  <span>&#8593;</span>
                  FIND OUT MORE <span>&darr;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageTitle;

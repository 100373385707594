"use client";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { MdMail } from "react-icons/md";
import { IoCallSharp } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import "./contact.scss";
import ContactusRegistrationDiamond from "../Json/ContactusRegistrationDiamond.json";
import ContactusPageHero from "../Json/ContactusPageHero.json";
import Lottie from "lottie-react";
function Contact() {
  return (
    <>
      <Header />
      <main className="contactPageWrapper">
        <Lottie animationData={ContactusPageHero}/>
        <section className="sectionPadding">
          <div className="container">
            <div className="row flexColumn justify-content-center">
              <div className="col-sm-10">
                <div className="primary-heading ">
                  Insights from the Experts
                </div>
                <p className="pt-2">
                  Gain valuable insights into the art and science of creating
                  homes that not only reflect your personality but also
                  integrate the latest innovations in smart living.
                </p>
              </div>
              <div className=" col-sm-11 ">
                <div className="contactCardWrapper">
                  <div className="card">
                    <img
                      src="../../images/contactTile1.png"
                      className="card-img-top img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="card">
                    <div className="card-body ">
                      <div className="card-icons">
                        <MdMail />
                      </div>
                      <h5 className="card-title">Email us</h5>
                      <p className="card-des">
                        For any inquiries, questions we are just an email away.
                      </p>
                      <a
                        href="mailto:hello@housecoin.com"
                        className="card-link"
                      >
                        Hello@housecoin.com
                      </a>
                    </div>
                  </div>
                  <div className="card">
                    <img
                      src="../../images/contactTile2.png"
                      className="card-img-top img-fluid"
                      alt="..."
                    />
                  </div>

                  <div className="card ">
                    <div className="card-body">
                      <div className="card-icons">
                        <IoCallSharp />
                      </div>
                      <h5 className="card-title">Call us</h5>
                      <p className="card-des">
                        We are available to speak with you and provide support.
                      </p>
                      <a href="tel:+123 456789" className="card-link">
                        +123 456789
                      </a>
                    </div>
                  </div>
                  <div className="card">
                    <img
                      src="../../images/contactTile3.png"
                      className="card-img-top img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="card ">
                    <div className="card-body">
                      <div className="card-icons">
                        <FaLocationDot />
                      </div>
                      <h5 className="card-title">Location</h5>
                      <p className="card-des">
                        Visit us at our office and let us assist you with your
                        requests
                      </p>
                      <p className="card-link">NEW YORK, 10036</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sectionPadding1 contactRegistration">
          <div className="container-fluid">
            <div className="row">
              <div className="d-none d-md-block col-md-6 p-0">
                <Lottie animationData={ContactusRegistrationDiamond}/>
              </div>
              <div className="col-sm-6 p-0">
                <div className="formBx">
                  <button className="page-btn btn-bg mt-4">Contact us</button>
                  <div className="formTitle">Stay in touch</div>
                  <form className="contactForm">
                    <div className="form-group">
                      <label className="heading">
                        FULL NAME
                        <input
                          type="text"
                          className="form-input"
                          id="fullName"
                          placeholder=""
                        />
                      </label>
                      <label className="heading">
                        EMAIL ADDRESS
                        <input
                          type="email"
                          className="form-input"
                          id="email"
                          placeholder=""
                        />
                      </label>
                      <label className="heading">
                        YOUR MESSAGE
                        <textarea
                          className="form-input"
                          id=""
                          placeholder=""
                        ></textarea>
                      </label>
                    </div>

                    <div className="submit-button">
                      <button type="submit" className="page-btn btn-bg mt-4">
                        Get started
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default Contact;

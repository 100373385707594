import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Blog from "./Pages/Blog";
import Agents from "./Pages/Agents";
import About from "./Pages/About";

const routes = (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/blog" element={<Blog />} />
    <Route path="/agents" element={<Agents />} />
    <Route path="/about" element={<About />}/>
  </Routes>
);

export default routes;

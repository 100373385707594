import React from "react";
import "./index.scss";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <div className="footerWrapper">
        <div className="background-overlay"></div>
        <div className="container">
          <div className="wrapperContainer">
            <div className="row justify-content-between align-items-center">
              <div className="col-auto">
                <div className="socialIcon">
                  <a href="/#">
                    <FaFacebook />
                  </a>
                  <a href="/#">
                    <FaInstagram />
                  </a>
                </div>
                <div className="companyAddress mt30 mb30">
                  2 THE OVAL, HARROGATE, HG2 9BA
                </div>
                <div className="terms">
                  <a href="/#">TERMS & CONDITIONS</a>
                </div>
              </div>
              <div className="col-auto">
                <div className="logo">
                  <a href="/#">
                    <img
                      src="../../images/logo.svg"
                      alt="logo"
                      className="img-fluid"
                    />
                  </a>
                </div>
                <div className="contactLink  mt30 mb30">
                  <Link className="nav-link" to="/contact">
                    CONTACT US <hr />
                  </Link>
                </div>
                <div className="contactNumber">
                  <a href="tel:420 350 199">420 350 199</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;

"use client";
import "./blog.scss";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import BlogPageRegistrationDiamond from "../Json/BlogPageRegistrationDiamond.json";
import { FaQuoteLeft } from "react-icons/fa6";
import { FaQuoteRight } from "react-icons/fa6";
import Lottie from "lottie-react";
import BlogPageHero from "../Json/BlogPageHero.json";
function Blog() {
  return (
    <>
      <Header />
      <main className="blogPageWrapper">
    <Lottie animationData={BlogPageHero}/>
        <section className="sectionPadding bg-light ">
          <div className="container">
            <div className="row flexColumn">
              <div className="col-sm-12">
                <div className="primary-heading ">
                  Insights from the Experts
                </div>
                <p>
                  Gain valuable insights into the art and science of creating
                  homes that not only reflect your personality but also
                  integrate the latest innovations in smart living.
                </p>
              </div>
              <div className="row justify-content-between">
                <div className=" col-md-5">
                  <div className="card ">
                    <div className="card-body">
                      <div className="card-image">
                        <img
                          src="../../images/blogTile1.png"
                          alt=""
                          className=" img-fluid"
                        />
                      </div>
                      <h5 className="card-title">
                        The Future Of Luxury Real Estate
                      </h5>
                      <p className="card-desc">
                        As the world becomes more connected and environmentally
                        conscious, luxury .....
                      </p>
                      <button className="page-btn  mt-4">
                        <span> &#8593;</span> Read more <span>&darr;</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-image">
                        <img
                          src="../../images/blogTile2.png"
                          className=" img-fluid"
                          alt=""
                        />
                      </div>
                      <h5 className="card-title">
                        Ensuring Safety And Serenity
                      </h5>
                      <p className="card-desc">
                        When it comes to luxury homes, security is not just a
                        necessity but a crucial .....
                      </p>
                      <button className="page-btn  mt-4">
                        <span> &#8593;</span> Read more <span>&darr;</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="card ">
                    <div className="card-body">
                      <div className="card-image">
                        <img
                          src="../../images/blogTile3.png"
                          alt=""
                          className=" img-fluid"
                        />
                      </div>
                      <h5 className="card-title">Luxury Home Design Trends </h5>
                      <p className="card-desc">
                        As we approach the new season, it's a great time to
                        start thinking about .....
                      </p>
                      <button className="page-btn mt-4">
                        <span> &#8593;</span> Read more <span>&darr;</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-image">
                        <img
                          src="../../images/blogTile4.png"
                          alt=""
                          className=" img-fluid"
                        />
                      </div>
                      <h5 className="card-title">Real Stories, Real Homes</h5>
                      <p className="card-desc">
                        From historic restorations to cutting-edge smart homes,
                        get an insider's look .....
                      </p>
                      <button className="page-btn mt-4">
                        <span> &#8593;</span> Read more <span>&darr;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sectionPadding1  pb150 bg-light">
          <div className="container mb30">
            <div className="col-sm-12 ">
              <div className="primary-heading ">Our ongoing projects</div>
              <p>
                Step into the realm of possibilities as we provide a sneak peek
                into the captivating narratives currently unfolding under our
                skilled hands.
              </p>
            </div>
            <div
              id="demo"
              className="carousel slide mt-100"
              data-bs-ride="carousel"
            >
              {/* The slideshow/carousel  */}
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row">
                    <div className=" col-lg-4">
                      <div className="carousel-image">
                        <img
                          src="../../images/blogTile5.png"
                          alt=""
                          className=" img-fluid"
                        />
                      </div>
                    </div>
                    <div className="  col-lg-7 ">
                      <div className="content">
                        <div className="title">Serenity haven</div>
                        <p className="paragraph">
                          Tranquil outskirts, offering a retreat from the urban
                          hustle.
                        </p>
                        <blockquote className="quotation">
                          <div>
                            <FaQuoteLeft />
                          </div>
                          <span>
                            Serenity Haven is a project nestled in the heart of
                            the suburbs, blending modern design with serene
                            surroundings. The remodel focuses on creating open
                            spaces, integrating smart living solutions, and
                            emphasizing natural light to establish a haven of
                            tranquility
                          </span>
                          <FaQuoteRight />
                        </blockquote>
                        <button className="page-btn btn-bordered mt-4">
                          <span> &#8593; </span> Learn more{" "}
                          <span> &darr; </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="  col-lg-4">
                      <div className="carousel-image">
                        <img
                          src="../../images/blogTile6.png"
                          alt=""
                          className=" img-fluid"
                        />
                      </div>
                    </div>
                    <div className=" col-lg-7 ">
                      <div className="content">
                        <div className="title">Urban Oasis Makeover</div>
                        <p className="paragraph">
                          Positioned in the bustling city center
                        </p>
                        <blockquote className="quotation">
                          “Immerse yourself in the epitome of urban luxury and
                          environmental consciousness as House Coin unveils the
                          Urban Oasis Makeover. This project seamlessly marries
                          the vibrant energy of urban living with the serenity
                          of an eco-modern retreat.”
                        </blockquote>
                        <button className="page-btn btn-bordered mt-4">
                          <span> &#8593; </span> Learn more{" "}
                          <span> &darr; </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="  col-lg-4">
                      <div className="carousel-image">
                        <img
                          src="../../images/blogTile7.png"
                          alt=""
                          className=" img-fluid"
                        />
                      </div>
                    </div>
                    <div className=" col-lg-7 ">
                      <div className="content">
                        <div className="title">Eco-Modern Retreat</div>
                        <p className="paragraph">
                          Set in a sustainable, green community
                        </p>
                        <blockquote className="quotation">
                          “Discover a haven where sustainability meets
                          sophistication, where cutting-edge design seamlessly
                          integrates with eco-conscious living - welcome to the
                          Eco-Modern Retreat, a distinctive project by House
                          Coin that redefines the essence of contemporary
                          living.”
                        </blockquote>
                        <button className="page-btn btn-bordered mt-4">
                          <span> &#8593; </span> Learn more{" "}
                          <span> &darr; </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Left and right controls/icons  */}
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#demo"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon"></span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#demo"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon"></span>
              </button>
            </div>
          </div>
        </section>

        <section className="sectionPadding2 contactRegistration">
          <div className="container-fluid">
            <div className="row">
              <div className="d-none d-md-block col-sm-6 p-0">
                <Lottie animationData={BlogPageRegistrationDiamond}/>
              </div>
              <div className="col-sm-6 p-0">
                <div className="formBx">
                  <button className="page-btn btn-bg mt-4">Stay updated</button>
                  <div className="formTitle">Subscribe to our Newsletter</div>

                  <form className="contactForm">
                    <div className="form-group">
                      <label className="heading">
                        FULL NAME
                        <input
                          type="text"
                          className="form-input"
                          id="fullName"
                          placeholder=""
                        />
                      </label>
                      <label className="heading">
                        EMAIL ADDRESS
                        <input
                          type="email"
                          className="form-input"
                          id="email"
                          placeholder=""
                        />
                      </label>
                      <div className="row form-check">
                        <label className="form-check-label">
                          <input type="checkbox" className="form-check-input" />
                          If you do not want to receive future updates via
                          email, please check this box
                        </label>
                      </div>
                    </div>

                    <div className="submit-button">
                      <button type="submit" className="page-btn btn-bg mt-4">
                        Subscirbe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
export default Blog;

import React from "react";
// import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import routes from "./routes";
// import React, { useEffect } from "react";
import "animate.css";

// import "./wow-setup";
// import "wowjs";
// import "@types/react-wow";
// import "./sass/main.scss";

function App() {
  // useEffect(() => {
  //   document.addEventListener("DOMContentLoaded", () => {
  //     const wow = new window.WOW();
  //     wow.init();
  //   });
  // }, []);

  return (
    <>
      <Router>{routes}</Router>
    </>
  );
}

export default App;
